import {FilterActionsText} from "./typing";
import {RelocationService} from "@common/services";

export const tripProtectionTypes = {
    RED_SKY_TI: "Red Sky Travel Insurance",
    VACASA_TP: "Vacasa Trip Protection",
    NO_INSURANCE: "Without Insurance",
    DONT_APPLY: "N/A",
    ADDON: "addon",
    TRIP_PROTECTION: "trip_protection",
};

export const defaultFilters = {
    group: "0",
    status: [RelocationService.STATUS_PENDING, RelocationService.STATUS_IN_PROGRESS].join(","),
    unitCode: "",
    priority: "",
    unassigned: 0,
    assignedTo: "",
    sort: "",
    createdAt: "",
    task: "",
    pageNumber: 1,
    pageSize: 10,
    isLatam: -1,
};

export const defaultFilterGenerator = {
    byAssignedTo: (assignedTo: string) => {
        return {assignedTo};
    },
};

export const RouteList = {
    queue: {
        all: "/queue",
        rank: "/queue/rank",
        mine: "/queue/mine",
    },
};

export enum CaseHistoryActions {
    ASSIGN = 1,
    UNASSIGN = 2,
    INITIATE = 3,
    FOLLOWUP = 4,
    SUBMIT = 5,
    GENERAL_NOTE = 6,
    COMPLETE = 7,
    REOPEN = 8,
    PRIORITY_UPDATED = 9,
    FOLLOWUP_UPDATED = 10,
    GUEST_SATISFACTION_REFUND_UPDATED = 11,
    FINANCES_UPDATED = 12,
    OWNER_BILLING_UPDATED = 13,
    GROUP_REMOVED = 14,
}

export enum OptionsAvailable {
    HOMES_AVAILABLE = 1,
    VACASA_UNIT_NO_RES_HOLD = 2,
    VACASA_UNIT_CREATING_RES_HOLD = 3,
    SPLIT_STAY = 4,
    LTR_CREATING_VACASA_HOLD = 5,
    OUTSIDE_BOOKING = 6,
    NO_HOMES_AVAILABLE = 7,
}

export const OptionsAvailableReservationBased = [
    OptionsAvailable.VACASA_UNIT_NO_RES_HOLD,
    OptionsAvailable.VACASA_UNIT_CREATING_RES_HOLD,
    OptionsAvailable.LTR_CREATING_VACASA_HOLD,
    OptionsAvailable.HOMES_AVAILABLE,
];

export enum CaseTasks {
    INITIATE = 1,
    FOLLOW_UP = 2,
    WAITING_IN_GUEST = 3,
    CANCEL_AND_REFUND = 4,
    CALL_GUEST = 5,
}

export const HistoryActionsOutboundContact = [RelocationService.HISTORY_ACTION_INITIATE, RelocationService.HISTORY_ACTION_FOLLOW_UP];
export const HistoryActionsOutboundContactDetails = {
    [RelocationService.HISTORY_ACTION_INITIATE]: {displayName: "Initiation attempt"},
    [RelocationService.HISTORY_ACTION_FOLLOW_UP]: {displayName: "Follow up"},
};
export const CaseTaskNextStep = {
    [CaseTasks.INITIATE]: {
        historyToPublish: RelocationService.HISTORY_ACTION_INITIATE,
        nextTask: CaseTasks.FOLLOW_UP,
    },
    [CaseTasks.FOLLOW_UP]: {
        historyToPublish: RelocationService.HISTORY_ACTION_FOLLOW_UP,
        nextTask: CaseTasks.FOLLOW_UP,
    },
};

// Limit of follow up as positive integer. If is 0 means no limit
export const FOLLOWUP_LIMIT = 0;
export const HOMES_UNIT_OPTION_LIMIT = 2;
export const OTHER_UNIT_OPTION_LIMIT = 3;
export const UNIT_OPTION_LIMIT = HOMES_UNIT_OPTION_LIMIT + OTHER_UNIT_OPTION_LIMIT;

export enum WrapUpOptions {
    MOVE_TO_NEW_UNIT = 4,
    CANCEL_AND_REFUND = 2,
    CANCEL_AND_FSC = 8,
    STAY_IN_HOME = 5,
    FM_NO_LONGER_NEEDED = 10,
}

export const FilterActionTexts: FilterActionsText[] = [
    {id: 1, status: defaultFilters.status, group: "0", text: "Relo | Active Cases"},
    {id: 2, status: RelocationService.STATUS_DONE.toString(), group: "0", text: "Relo | Resolved Cases"},
    {id: 3, status: defaultFilters.status, group: "1", text: "Group | Active Cases"},
    {id: 4, status: RelocationService.STATUS_DONE.toString(), group: "1", text: "Group | Resolved Cases"},
    {id: 5, status: "", group: "", text: "Resolve"},
    {id: 6, status: "", group: "", text: "Assign"},
];

// FM Conclusions not allowed to display the "New reservation(s) add’l cost" item in Owner billing section
export const conclusionsNotAllowedToDislayAddCostItem: number[] = [WrapUpOptions.CANCEL_AND_REFUND, WrapUpOptions.CANCEL_AND_FSC];

export const relocationPriorityDesc = [
    {id: 1, rank: 1, name: "MSFM", desc: "Mid Stay Force Move"},
    {id: 2, rank: 2, name: "ESP-MSFM", desc: "Mid Stay Force Move ESP"},
    {id: 3, rank: 3, name: "TODAY", desc: "Today"},
    {id: 4, rank: 4, name: "ESP-TODAY", desc: "Today ESP"},
    {id: 5, rank: 5, name: "LMFM", desc: "Last Minute Force Move"},
    {id: 6, rank: 6, name: "ESP-LMFM", desc: "Last Minute Force Move ESP"},
    {id: 7, rank: 8, name: "< 30 DAYS", desc: "Less Than 30 Days"},
    {id: 8, rank: 7, name: "HOLIDAY", desc: "Holiday"},
    {id: 9, rank: 9, name: "ESP", desc: "Extended Stay Program"},
    {id: 10, rank: 10, name: "30+ DAYS", desc: "More than 30 Days"},
    {id: 11, rank: 11, name: "ESP 2+ MO", desc: "More Than 2 Months ESP"},
    {id: 12, rank: 12, name: "90+ DAYS", desc: "More Than 90 Days"},
];

export const relocationPriorities: {[key: string]: number} = {
    MSFM: 1,
    "ESP-MSFM": 2,
    TODAY: 3,
    "ESP-TODAY": 4,
    LMFM: 5,
    "ESP-LMFM": 6,
    HOLIDAY: 8,// take the id from the priority table, there Holiday and LESS_30_DAYS are inverted
    "< 30 DAYS": 7,// take the id from the priority table, there Holiday and LESS_30_DAYS are inverted
    LTR: 9,
    "30+ DAYS": 10,
    "ESP 2+ MO": 11,
    "90+ DAYS": 12,
};

export enum TableType {
    TABLE_CASES = 1,
    TABLE_REPORT = 2,
}

export enum TabsType {
    ALL_CASES = 0,
    MY_CASES = 1,
    CASE_LOAD = 2,
}

export enum TurnoversTypes {
    TURNOVER_NO_CHECK_IN_OR_OUT = 0,
    TURNOVER_CHECK_IN_OR_OUT = 1,
    TURNOVER_CHECK_OUT_ONLY = 2,
    TURNOVER_CHECK_IN_ONLY = 3,
}

export enum FilterActionOptions {
    ACTIVE_CASES = 1,
    RESOLVED_CASES = 2,
    GROUP_ACTIVE_CASES = 3,
    GROUP_RESOLVED_CASES = 4,
    RESOLVE = 5,
    ASSIGN = 6,
    LATAM_ACTIVE_CASES = 7,
    LATAM_RESOLVED_CASES = 8,
}

export const typesOfBooking = [
    {id: 1, name: "Vacasa Unit"},
    {id: 2, name: "Outside Booking"},
];

export enum TypesOfBooking {
    VACASA_UNIT = 1,
    OUTSIDE_BOOKING = 2,
}

//Maximum amount of Split Stays
export const SPLITSTAY_UNITS_LIMIT = 10;

export const ADMIN_USERS = [
    "oscar.yanez@vacasa.com",
    "beatriz.beltran@vacasa.com",
    "catherine.veitia@vacasa.com",
    "jorge.diazdeltoro@vacasa.com",
    "edwin.kallens@vacasa.com",
    "ricardo.valdes@vacasa.com",
    "sergio.vergara@vacasa.com",
    "dan.rattigan@vacasa.com",
    "kevin.mai@vacasa.com",
    "sean.meyer@vacasa.com",
    "lindsay.moore@vacasa.com",
    "jeremy.poisson@vacasa.com",
    "james.stearns@vacasa.com",
    "william.huckaby@vacasa.com",
    "tina.crouch@vacasa.com",
    "gonzalo.jorquera@vacasa.com",
    "madison.wallace@vacasa.com",
    "tara.whittlesay@vacasa.com",
    "christina.gokey-rippy@vacasa.com"
];

export const relocationCreatedAtDesc = [
    {id: 1, text: "Last 24hrs", day: 1},
    {id: 2, text: "Last 3 days", day: 3},
    {id: 3, text: "Last 7 days", day: 7},
    {id: 4, text: "Last 30 days", day: 30},
];

export const relocationTaskFilter = [
    {id: 1, text: "Initiate FM", value: "1"},
    {id: 2, text: "Follow Up", value: "2"},
];

//Bulk Actions
export const BULK_RESOLVE_ACTION = "resolve";
export const BULK_ASSIGN_ACTION = "assign";

export const BULK_SELECTION_LIMIT = 40;

//Form Submission Steps
export enum FormSubmissionSteps {
    TYPE_OF_FM = 1,
    URGENT_FM = 2,
    NON_URGENT_FM = 3,
    BILLING = 7,
    RES_ID = 4,
    UNIT_CODES = 5,
    UNAVAILABLE_DATES = 6,
    OWNER_BILLED = 8,
    VACASA_BILLED = 9,
    SPLIT_BILLED = 10,
    RESUME = 11,
}

//Tabs
export enum NAVIGATION_TABS {
    INITIATION = "",
    FILES = "files",
    WRAPUP = "wrapup",
}

//Routes.
export const ROUTES = {
    HOLIDAYS: "holidays",
    SUBMISSION_FORM: "submission",
    QUEUE: "queue",
    QUEUE_ALL_CASES: "",
    QUEUE_MY_CASES: "mine",
    QUEUE_CASES_BY_RANK: "rank",
};

//Types of requests in Form Submission
export const requestType = {
    reservation: 1,
    unit: 2,
};

//Non urgent options
export const nonUrgentOptions = [
    {id: 1, option: "resId", name: "type", value: "1", text: "I know the Reservation ID"},
    {id: 2, option: "unitCode", name: "type", value: "2", text: "I want to provide the Unit Code and date range instead"},
];

//Redux Actions
export const REDUX_ACTIONS = {
    PENDING: "pending",
    FULFILLED: "fulfilled",
    REJECTED: "rejected",
};

//Guest Satisfaction Refund Reasons
export const guestSatisfactionRefundReasons = [
    {id: 1, text: "Cancel/Refund additional"},
    {id: 2, text: "Outside Booking additional"},
    {id: 3, text: "Loss of amenity"},
    {id: 4, text: "Other"},
];
export const DEFAULT_REFUND_REASON = 4;

// Temporarily constant
export const WrapUpOptionsWithRedux = [
    WrapUpOptions.STAY_IN_HOME,
    WrapUpOptions.FM_NO_LONGER_NEEDED,
    WrapUpOptions.CANCEL_AND_REFUND,
    WrapUpOptions.CANCEL_AND_FSC,
    WrapUpOptions.MOVE_TO_NEW_UNIT,
];

export const DEFAULT_COUNT_MY_CASES = {
    allCases: -1,
    group: "0,1",
};

export const DEFAULT_RETRY_TIME = 60000;
